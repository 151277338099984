.grid {
    &__image {
        max-width: 40px;
        max-height: 40px;
        height: auto;
    }
    &__image-wide {
        max-width: 90px;
        max-height: 45px;
        height: auto;
    }

    &__icon {
        border: 0;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        text-transform: none;
        will-change: transform;
    }
}
